import { ProductRelease, Variant } from '@adsk/offsite-dc-sdk';
import { initializeConfigurableProductProperties } from '@mid-react-common/revit-components';
import { mergeProductReleaseInputsWithVariantInputValues } from '../utils';
import DataContext from 'context/Data.context';
import TabsContext from 'context/Tabs/Tabs.context';
import { configureAndSelectTabs } from 'context/Tabs/tabsStore';
import { SelectedRFAInstance, ProductsUtils, getSelectedRFAInstance, getVariant } from 'mid-addin-lib';
import {
  AccBridgeDownloadUrlQueryParams,
  AccBridgeSourceProjectDataQueryParams,
  getDcApiServiceInstance,
} from 'mid-api-services';
import React, { useContext } from 'react';
import { ampli } from '../ampli';
import { ConfigureScreen } from './ConfigureScreen/ConfigureScreen';
import SelectScreen from './SelectScreen/SelectScreen';
import { MIDSelectedRFAInstance, getMIDSelectedRfaInstanceData } from 'global/selectedRFAInstance.utils';
import { DCProductUIExtension, DCRules } from 'mid-types';
import { useQuery } from '@tanstack/react-query';

const handleAmplitudeInstanceOpenEvent = (
  variant: Variant,
  productRelease: DCProductUIExtension<ProductRelease>,
  selectedRFAInstance: SelectedRFAInstance,
) => {
  // Amplitude Event
  ampli.midefInstanceOpen({
    variantName: variant.name,
    variantId: variant.variantId,
    releaseNumber: productRelease.release,
    projectId: productRelease.tenancyId,
    productId: productRelease.contentId,
    hasRules: !!productRelease.rules,
    numberOfInputs: productRelease.inputs.length,
    releaseStatus: productRelease.status,
    selectedRepresentation: selectedRFAInstance.modelState,
  });
};

const GET_INPUTS_FROM_INSTANCE_KEY = 'getInputsFromSelectedInstance';

export const ScreenManager: React.FC = () => {
  const dcApiServiceInstance = getDcApiServiceInstance();

  const { selectedTab } = useContext(TabsContext);
  const {
    setRfaInstance,
    setCurrentProductRelease,
    setCurrentVariantOfInstance,
    setCurrentProductReleaseOfInstance,
    setSelectedCachedVariant,
    updateConfigurableProductInputs,
    setProductReleasesList,
    dispatchConfigurableProductUpdateAction,
    setSelectedRepresentation,
    setIsFormInitializing,
  } = useContext(DataContext);

  const getInputsFromSelectedInstance = async (): Promise<Variant> => {
    const selectedRFAInstance: SelectedRFAInstance = await getSelectedRFAInstance();

    // Transform the selectedRFAInstance into an object (MIDSelectedRFAInstance)
    // which we can use in the frontend, containing ACCBridge data as well
    const transformedSelectedRFAInstance: MIDSelectedRFAInstance = getMIDSelectedRfaInstanceData(selectedRFAInstance);
    setRfaInstance(transformedSelectedRFAInstance);

    const { tenancyId, productId, targetProjectId, sourceProjectId, variantId, releaseNumber, modelState, sourceFolderUrn } =
      transformedSelectedRFAInstance;

    // Incoming ACCBridge data needed for Download URL requests
    const incomingAccBridgeDownloadUrlData: AccBridgeDownloadUrlQueryParams | undefined = sourceProjectId
      ? {
          targetProjectId,
          sourceProjectId,
        }
      : undefined;
    // Incoming ACCBridge data needed for Releases and Variants requests
    const incomingAccBridgeProjectData: AccBridgeSourceProjectDataQueryParams | undefined = incomingAccBridgeDownloadUrlData
      ? {
          ...incomingAccBridgeDownloadUrlData,
          sourceFolderUrn,
        }
      : undefined;

    // No need to await, avoiding stop the UI
    dcApiServiceInstance
      .getProductReleasesList({
        projectId: tenancyId,
        productId,
        incomingAccBridgeData: incomingAccBridgeProjectData,
      })
      .then((releases) => {
        setProductReleasesList(releases);
      });

    const variant = await getVariant({
      projectId: tenancyId,
      productId,
      variantId,
      incomingAccBridgeData: incomingAccBridgeProjectData,
    });

    const productRelease: DCProductUIExtension<ProductRelease> = await dcApiServiceInstance.getProductRelease({
      projectId: tenancyId,
      productId,
      releaseNumber: releaseNumber || variant.release,
      incomingAccBridgeData: incomingAccBridgeProjectData,
    });

    // The product release can be a new instance with a "rulesKey" attribute
    // or the rules directly stored in the "rules" attribute
    const productReleaseRules: DCRules | undefined =
      (await ProductsUtils.checkAndDownloadProductRulesFromKey({
        tenancyId,
        rulesKey: productRelease.rulesKey,
        incomingAccBridgeData: incomingAccBridgeDownloadUrlData,
      })) || productRelease.rules;

    const mergedProductReleaseVariantInputs = mergeProductReleaseInputsWithVariantInputValues(
      productRelease.inputs,
      variant.inputs,
    );

    const productReleaseWithRules = { ...productRelease, rules: productReleaseRules };
    // After we set currentProductRelease, useEffect will trigger the code runner in useProductCustomizationFormInRevit
    setCurrentProductRelease(productReleaseWithRules);
    setCurrentProductReleaseOfInstance(productReleaseWithRules);
    initializeConfigurableProductProperties(
      productReleaseWithRules,
      dispatchConfigurableProductUpdateAction,
      setSelectedRepresentation,
    );
    setCurrentVariantOfInstance(variant);
    setSelectedCachedVariant(variant);
    setSelectedRepresentation(modelState);
    updateConfigurableProductInputs(mergedProductReleaseVariantInputs);

    handleAmplitudeInstanceOpenEvent(variant, productReleaseWithRules, selectedRFAInstance);

    setIsFormInitializing(true);

    return variant;
  };

  useQuery({
    queryKey: [GET_INPUTS_FROM_INSTANCE_KEY],
    queryFn: getInputsFromSelectedInstance,
  });

  if (selectedTab === configureAndSelectTabs.TAB_CONFIGURE) {
    return <ConfigureScreen />;
  }
  return <SelectScreen />;
};
