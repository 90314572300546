import { styled } from '@mui/material/styles';

export const ConfigureScreenEditInPlaceWrapper = styled('div')`
  display: flex;
  overflow: auto;
  height: calc(
    100vh -
      ${({ theme }) => `${theme.var.midefHeaderWithTabsHeight + theme.var.midefFooterHeight + theme.var.borderHeight}px`}
  );
`;

export const ProductCustomizationFooterWrapper = styled('div')`
  button.product-customization-footer-generate-button {
    color: ${({ theme }) => theme.colors.primary.white};
    background-color: ${({ theme }) => theme.palette.primary.main};
    box-shadow:
      0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    &.Mui-disabled {
      background-color: ${({ theme }) => theme.colors.primary.charcoal[200]};
      color: ${({ theme }) => theme.colors.primary.charcoal[400]};
      box-shadow: none;
    }
  }
`;
