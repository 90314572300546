import { StateSetter } from '@mid-react-common/common';
import { useState } from 'react';

export const configureAndSelectTabs = {
  TAB_CONFIGURE: 0,
  TAB_SELECT: 1,
} as const;
export type ConfigureAndSelectTabs = (typeof configureAndSelectTabs)[keyof typeof configureAndSelectTabs];

export interface TabsStore {
  selectedTab: ConfigureAndSelectTabs;
  setSelectedTab: StateSetter<ConfigureAndSelectTabs>;
}

export const useTabsStore = (): TabsStore => {
  const [selectedTab, setSelectedTab] = useState<ConfigureAndSelectTabs>(configureAndSelectTabs.TAB_CONFIGURE);

  return {
    selectedTab,
    setSelectedTab,
  };
};
