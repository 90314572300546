import { defineWindowHostApiMock, mockHostApi, resizeHostWindow } from 'mid-addin-lib';
import { GlobalStyle, LDProviderConfigWithAnonymousAnalytics } from '@mid-react-common/common';
import GlobalStyles from '@mui/material/GlobalStyles';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { initializeAmplitude, mswDcApiHandlers, mswForgeApiHandlers, mswMiscellaneousWebHandlers } from 'mid-utils';
import { http } from 'msw';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ampli } from './ampli';
import reportWebVitals from './reportWebVitals';
import { setupWorker } from 'msw/browser';
import { ENVIRONMENT } from 'mid-types';

const defaultWindowWidth = 400;

if (import.meta.env.VITE_CYPRESS_BUILD === 'true' || import.meta.env.VITE_ENVIRONMENT === ENVIRONMENT.MOCK) {
  // TODO: add mswUnhandledPathHandler to handlers list & add unmocked handlers as needed
  // If added now, the app will crash when run in VITE_ENVIRONMENT === ENVIRONMENT.MOCK mode
  // https://jira.autodesk.com/browse/TRADES-5833
  const handlers = [...mswForgeApiHandlers, ...mswDcApiHandlers, ...mswMiscellaneousWebHandlers];
  const mockWorker = setupWorker(...handlers);

  // Make the `worker` and `http` references available globally for cypress testing
  window.msw = { mockWorker, http };
  // Mocking HostApi library
  defineWindowHostApiMock(window, mockHostApi);
  // Starting msw mock worker to intercept FORGE API calls
  await mockWorker.start();
}

// It is a good practice to hoist the <GlobalStyles /> to a static constant, to avoid rerendering. This will ensure that the
// <style> tag generated would not recalculate on each render.
const globalStyles = <GlobalStyles styles={GlobalStyle} />;

(async (): Promise<void> => {
  initializeAmplitude.bind(ampli)();
  const LDProvider = await asyncWithLDProvider(LDProviderConfigWithAnonymousAnalytics);

  // set the browser window with to the minimum allowed value to avoid horizontal scrollbars in the form
  if (window.innerWidth < defaultWindowWidth) {
    resizeHostWindow(defaultWindowWidth, window.innerHeight);
  }

  const container = document.getElementById('root');
  const root = createRoot(container!);

  root.render(
    <React.StrictMode>
      {globalStyles}
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>,
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
