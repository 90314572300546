import { DCInput, PostVariantPayload, ReleaseStatus, Variant } from '@adsk/offsite-dc-sdk';
import {
  PrintMessageDialog,
  ProductCustomizationForm,
  ProductDetails,
  productCustomizationTestIds,
  transformToVariantPayloadRfaOnly,
  usePrintMessageDialog,
} from '@mid-react-common/addins';
import { ModalContext, NotificationContext, PreviewWrapper } from '@mid-react-common/common';
import {
  ConfigurePreviewContainer,
  ConfigureProductCustomizationContainer,
  ConfigureScrollableContainer,
  NameAndRelease,
  ProductCustomizationFooter,
  VariantFormState,
  VariantThumbnail,
  useProductCustomizationFooter,
  useProductCustomizationFormInRevit,
  useRepresentation,
} from '@mid-react-common/revit-components';
import Divider from '@mui/material/Divider';
import DataContext from 'context/Data.context';
import { createVariant } from 'mid-addin-lib';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ampli } from '../../ampli';
import text from '../../global/mid-edit-form.text.json';
import { ConfigureScreenEditInPlaceWrapper, ProductCustomizationFooterWrapper } from './ConfigureScreen.styles';
import { useGenerateVariant } from './useGenerateVariant';
import { setDefaultInputValuesIfNotApplicable } from 'mid-utils';

// Amplitude event handler
const handleAmplitudeVariantsGenerateEvent = (postedVariant: Variant) => {
  ampli.midefVariantGenerate({
    variantId: postedVariant.variantId,
    variantName: postedVariant.name,
    releaseNumber: postedVariant.release,
    projectId: postedVariant.tenancyId,
    productId: postedVariant.contentId,
    numberOfInputs: postedVariant.inputs.length,
  });
};

export const ConfigureScreen: React.FC = () => {
  const { isMessageDialogOpen, dialogMessage, showMessageDialog, closeMessageDialog } = usePrintMessageDialog();

  const { dontShowAgain, setModalState } = useContext(ModalContext);
  const { showNotification, logAndShowNotification } = useContext(NotificationContext);
  const {
    rfaInstance,
    currentProductRelease,
    currentVariantOfInstance,
    selectedCachedVariant,
    configurableProductProperties,
    variantFormState,
    selectedRepresentation,
    isFormInitializing,
    productReleasesListLength,
    setSelectedCachedVariant,
    setVariantFormState,
    updateConfigurableProductInputs,
    setSelectedRepresentation,
    setReFetchCachedVariants,
    resetConfigurableProductProperties,
    setIsFormInitializing,
    setPostedVariantIdsList,
  } = useContext(DataContext);

  const incomingAccBridgeData = useMemo(
    () => ({
      sourceFolderUrn: rfaInstance?.sourceFolderUrn,
      sourceProjectId: rfaInstance?.sourceProjectId,
      targetProjectId: rfaInstance?.targetProjectId,
    }),
    [rfaInstance],
  );

  useEffect(() => {
    // In revit app, every time this component is mounted, the form isFormInitializing is true,
    // since the variable is in local state. This is not the case for MIDEF.
    // isFormInitializing is the dataStore, so it will be false when the dataStore is initialized.
    // This causes an issue when you switch tabs or select a cached variant, since the rules are not executed.
    // To fix this, we need to set isFormInitializing to true when the component is mounted.

    setIsFormInitializing(true);
  }, [setIsFormInitializing]);

  const [savedVariant, setSavedVariant] = useState<Variant | undefined>();

  // data and functions required for ProductCustomizationForm
  const { productCustomizationFormError, isFormDataValid, setIsFormDataValid, inputsError, handleInputUpdate, formRules } =
    useProductCustomizationFormInRevit({
      currentProductRelease,
      configurableProductProperties,
      updateConfigurableProductInputs,
      setVariantFormState,
      logAndShowNotification,
      showMessageDialog,
      isFormInitializing,
      setIsFormInitializing,
    });

  const postVariant = useCallback(
    async (targetProjectId: string, productId: string, release: number): Promise<Variant> => {
      // We set the default values for the inputs that are not applicable for the current inputs.
      // This makes sure that this variant gets cached no matter what the input value is for the inputs
      // that are not applicable.
      const inputPayload: DCInput[] = currentProductRelease?.inputs
        ? setDefaultInputValuesIfNotApplicable(configurableProductProperties.inputs, currentProductRelease.inputs)
        : configurableProductProperties.inputs;

      const postVariantPayload: PostVariantPayload = {
        ...transformToVariantPayloadRfaOnly(
          { ...configurableProductProperties, inputs: inputPayload },
          selectedRepresentation,
        ),
        release,
      };

      const savedVariant: Variant = await createVariant({
        projectId: targetProjectId,
        productId,
        variantPayload: postVariantPayload,
        incomingAccBridgeData,
      });

      setSavedVariant(savedVariant);
      setSelectedCachedVariant(savedVariant);

      return savedVariant;
    },
    [
      currentProductRelease?.inputs,
      configurableProductProperties,
      selectedRepresentation,
      incomingAccBridgeData,
      setSelectedCachedVariant,
    ],
  );

  // TODO: Refactor UI to take advantage of cached variant.
  // see: https://jira.autodesk.com/browse/TRADES-6404
  const { handleGenerateNewVariantClick } = useGenerateVariant({
    currentProductRelease,
    rfaInstance,
    postVariant,
    setVariantFormState,
    setReFetchCachedVariants,
    dontShowAgain,
    setModalState,
    showNotification,
    handleAmplitudeVariantsGenerateEvent,
    setPostedVariantIdsList,
  });

  // data and functions required for ProductCustomizationFooter
  const { cachedVariantThumbnail, handleResetClick } = useProductCustomizationFooter({
    currentProductRelease,
    selectedCachedVariant,
    selectedRepresentation,
    incomingAccBridgeData,
    setVariantFormState,
    resetConfigurableProductProperties,
    setSelectedCachedVariant,
    setIsFormInitializing,
  });

  const handleRepresentationStateChange = (): void => {
    if (setVariantFormState) {
      setVariantFormState(VariantFormState.EDITING_NEW_VARIANT);
    }
  };

  const {
    productReleaseRFAOutput,
    rfaRepresentationDropdownItems,
    selectedRepresentationDropdownItem,
    handleRfaRepresentationSelection,
  } = useRepresentation({
    configurableProductProperties,
    selectedRepresentation,
    setSelectedRepresentation,
    selectedVariant: selectedCachedVariant,
    handleRepresentationStateChange,
  });

  const { productCustomizationFormWrapper } = productCustomizationTestIds;

  return (
    <>
      <ConfigureScreenEditInPlaceWrapper>
        <ConfigureProductCustomizationContainer data-testid={productCustomizationFormWrapper}>
          <ProductCustomizationForm
            inactive={
              isFormInitializing ||
              variantFormState === VariantFormState.GENERATING_NEW_VARIANT ||
              currentProductRelease?.status === ReleaseStatus.OBSOLETE
            }
            error={productCustomizationFormError}
            inputs={configurableProductProperties.inputs}
            inputsError={inputsError}
            handleInputUpdate={handleInputUpdate}
            setIsFormDataValid={setIsFormDataValid}
            formLayoutRules={formRules}
            isFormLoading={isFormInitializing}
          />
        </ConfigureProductCustomizationContainer>
        <Divider orientation="vertical" />
        <ConfigurePreviewContainer>
          {currentProductRelease && (
            <NameAndRelease
              currentProductRelease={currentProductRelease}
              productReleasesListLength={productReleasesListLength}
            />
          )}
          <ConfigureScrollableContainer>
            <PreviewWrapper>
              <VariantThumbnail
                tenancyId={currentProductRelease?.tenancyId}
                isLoading={variantFormState === VariantFormState.GENERATING_NEW_VARIANT}
                thumbnail={savedVariant?.thumbnail || cachedVariantThumbnail || currentVariantOfInstance?.thumbnail}
                alternateMessage={
                  variantFormState === VariantFormState.GENERATING_NEW_VARIANT ? text.generatingNewVariant : undefined
                }
                incomingAccBridgeData={incomingAccBridgeData}
              />
            </PreviewWrapper>
            <ProductDetails
              title={text.MIDEditForm.productDetails}
              productCategory={productReleaseRFAOutput?.options?.category || ''}
              productFamily={productReleaseRFAOutput?.options?.family || ''}
              representations={productReleaseRFAOutput?.options?.modelStates || []}
              releaseNotes={currentProductRelease?.notes}
            />
          </ConfigureScrollableContainer>
        </ConfigurePreviewContainer>
      </ConfigureScreenEditInPlaceWrapper>
      <Divider />
      <ProductCustomizationFooterWrapper>
        <ProductCustomizationFooter
          handleResetClick={handleResetClick}
          handleGenerateNewVariantClick={handleGenerateNewVariantClick}
          handleRfaRepresentationSelection={handleRfaRepresentationSelection}
          variantFormState={variantFormState}
          isFormDataValid={isFormDataValid}
          selectedRepresentationDropdownItem={selectedRepresentationDropdownItem}
          representationDropdownItems={rfaRepresentationDropdownItems}
          currentProductReleaseStatus={currentProductRelease?.status}
        />
      </ProductCustomizationFooterWrapper>
      <PrintMessageDialog isMessageDialogOpen={isMessageDialogOpen} closeMessageDialog={closeMessageDialog} isAddin>
        {dialogMessage}
      </PrintMessageDialog>
    </>
  );
};
